import { createSelector } from 'reselect';
import { getLocale } from '@peloton/env/redux/selectors';
import { GUIDE_MEMBERSHIP_PRICING } from '@peloton/internationalize/consolidatedAppConfigs/models';
import { Locale, isLocale } from '@peloton/internationalize/models/locale';
import { getIsToggleActive } from '@ecomm/feature-toggle';
import { isGuideBundleTypeMatch } from '@ecomm/shop/models/Bundle';
import type { BundleType } from '@ecomm/shop/models/BundleType';
import { localeToMembershipPrice } from './utils';

// TODO: get this from plan in api instead of calculating based on Locale
// this is mostly to reduce dependency on intl as a stop-gap
export const getDeviceMembershipPrice = createSelector(
  getLocale,
  // @ts-expect-error
  getIsToggleActive('increasedAllAccessMembershipPrice'),
  (locale: Locale, isIncreasedAllAccessMembershipPriceEnabled: boolean) => {
    // Explicitly pass the arguments to the function to ensure type safety
    return localeToMembershipPrice(locale, isIncreasedAllAccessMembershipPriceEnabled);
  },
);

export const getMembershipPrice = (device: BundleType) => {
  if (isGuideBundleTypeMatch(device)) {
    return createSelector([getLocale], (locale: Locale) => {
      if (isLocale(locale)) {
        return GUIDE_MEMBERSHIP_PRICING[locale];
      }

      // Return a default value or handle the case when locale is not found
      return GUIDE_MEMBERSHIP_PRICING[Locale.EnglishUnitedStates]; // Default to US pricing
    });
  }

  return getDeviceMembershipPrice;
};
